<template>
  <div class="row">
    <div class="col-12">
      <v-row style="margin-top: 0px">
        <v-col cols="12" sm="12" md="3" class="pl-5" style="margin-right: 0">
          <v-select
            v-model="channelType"
            :items="items"
            item-value="id"
            item-text="name"
            label="Channel #  "
            class="channel-type"
            style="min-width: 100%"
            @input="selectChannelType(channelType)"
            solo
            ><template v-slot:prepend>
              <v-icon color="blue darken-2">mdi-television</v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="13"
          sm="12"
          md="3"
          class="text-right pl-5"
          style="margin-right: 0px"
        >
          <v-select
            v-model="currencyType"
            :items="items2"
            item-value="id"
            item-text="name"
            label="Channel #  "
            class="channel-type"
            style="min-width: 100%"
            @input="selectCurrencyType(currencyType)"
            solo
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="12" md="2" class="text-right mb-3">
          <v-text-field
            v-model="v_price"
            append-icon="mdi-magnify"
            label="Price"
            single-line
            hide-details
            clearable
            type="number"
            class="pl-5"
          />
        </v-col>
        <v-col cols="12" sm="12" md="2" class="text-right mb-3">
          <v-text-field
            v-model="v_search"
            append-icon="mdi-magnify"
            label="Group"
            single-line
            hide-details
            clearable
            class="pl-5"
          />
        </v-col>
        <v-col cols="12" sm="12" md="2" class="text-right pr-5 mb-3">
          <v-btn
            class="white--text btn-custom mr-5"
            color="primary darken-1"
            depressed
            @click="getData()"
          >
            SEARCH
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: [
    "darkmode",
    "status",
    "params",
    "dataDelete",
    "userPermission",
    "user",
    "time",
  ],
  data() {
    return {
      data: {
        descending: true,
        sortBy: "_id",
        page: 1,
        rowsPerPage: 10,
        fields: [
          "_id",
          "user_name",
          "balance",
          "amount",
          "amount_win",
          "app_id",
          "public_ip",
        ],
        search: "",
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
        channelType: 1,
        currencyType: 1,
      },
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      v_startDate: null,
      v_endDate: null,
      v_search: "",
      v_price: 0,
      items: [
        { name: "CO1", id: 1 },
        { name: "CO2", id: 2 },
        { name: "CO3", id: 3 },
        { name: "CO4", id: 4 },
        { name: "KL1", id: 5 },
        { name: "BA1", id: 6 },
        { name: "BA2", id: 7 },
        { name: "TD1", id: 8 },
        { name: "TD2", id: 9 },
        { name: "LO1", id: 10 },
        { name: "YU1", id: 11 },
        { name: "AP1", id: 12 },
        { name: "FT1", id: 13 },
        { name: "TS1", id: 14 },
      ],
      channelType: 1,
      items2: [
        { name: "ដុល្លា", id: 1 },
        { name: "រៀល", id: 2 },
      ],
      currencyType: 1,
      startTime: "00:00",
      endTime: "23:59",
    };
  },
  watch: {
    time: function (newVal) {
      // Emit this information to the parents component
      this.startDate = newVal.startDate;
      this.endDate = newVal.endDate;
    },
  },
  mounted() {
    if (this.$cookies.get("channel-type")) {
      this.channelType = parseInt(this.$cookies.get("channel-type"));
    }
    if (this.$cookies.get("currency-type")) {
      this.currencyType = parseInt(this.$cookies.get("currency-type"));
    }
  },
  computed: {
    titleInfo: function () {
      return "List Top Range Winner";
    },
    reportUrl: function () {
      return "getViewTopRangeWinnerApiBranch";
    },
  },
  methods: {
    selectChannelType(channelType) {
      this.$cookies.set("channel-type", channelType);
      this.channelType = parseInt(this.$cookies.get("channel-type"));
      if (
        parseInt(this.$cookies.get("channel-type")) == 2 ||
        parseInt(this.$cookies.get("channel-type")) == 3 ||
        parseInt(this.$cookies.get("channel-type")) == 4
      )
        this.$connectSocket(
          this.$cookies.get("token"),
          this.$cookies.get("channel-type")
        );
      //this.$router.go();
    },
    selectCurrencyType(currencyType) {
      this.$cookies.set("currency-type", currencyType);
      this.currencyType = parseInt(this.$cookies.get("currency-type"));
      if (
        parseInt(this.$cookies.get("currency-type")) == 1 ||
        parseInt(this.$cookies.get("currency-type")) == 2 ||
        parseInt(this.$cookies.get("currency-type")) == 3
      )
        this.$connectSocket(
          this.$cookies.get("token"),
          this.$cookies.get("currency-type")
        );
      //this.$router.go();
    },
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },
    async getData() {
      let formatStartDate = this.startDate + "T" + this.startTime + ":00";
      let formatEndDate = this.endDate + "T" + this.endTime + ":59";
      this.data.startDate = formatStartDate;
      this.data.endDate = formatEndDate;
      this.data.search = this.v_search;
      this.data.price = this.v_price ? parseInt(this.v_price) : 0;
      this.data.channelType = this.channelType;
      this.data.currencyType = this.currencyType;
      // console.log("this.reportUrl:",this.reportUrl);
      let preData = {
        data: this.data,
        user_id: this.user._id,
        reportUrl: this.reportUrl,
      };
      // console.log('preData:', preData);
      this.fetchListStatement(preData).then(() => {
        // console.log("into fetch list statement");
        let date = {
          startDate: this.startDate,
          endDate: this.endDate,
          price: this.v_price ? parseInt(this.v_price) : 0,
          search: this.v_search ? this.v_search : "",
        };
        this.$emit("child-checkbox", date);
      });
    },
    ...mapActions("$_statementTopRangeWinnerReports", [
      "fetchListStatement",
      "fetchChannelType",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.btn-custom {
  position: relative;
  // right: 250px;
  top: 10px;
  padding: 15px !important;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 15px !important;
}
@media (max-width: 768px) {
  .v-menu__content {
    left: 63px !important;
  }
}
</style>
